import React, { useState } from 'react';
import logo from './media/lisual-logo.png';
import './LandingPage.css'
import logocamara from './media/camera.png';
import logodrone from './media/drone.png';
import logomonitor from './media/monitor.png';
import landing1 from './media/landing1.png';
import landing2 from './media/landing2.png';
import landing3 from './media/landing3.png';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase-config';
import { BrowserRouter, Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TidioChat from './tidio';
import WhatsAppChatButton from './whatsapp';
import WarningPopup from './warningPopup'

const LandingPage = () => {
    const [keyword, setKeyword] = useState('');
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
      e.preventDefault(); 
      
      const usuariosRef = collection(db, 'usuarios');
      const q = query(usuariosRef, where("identifier", "==", keyword)); // Assuming 'id' is the field name and 'keyword' is the ID we're looking for
      
      try {
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          // User exists in the database
          navigate('/dashboard', { state: { keyword } });
          console.log('Submitted keyword:', keyword);
        } else {
          // User does not exist
          console.log("no");
          
          toast.warning('Acceso denegado: el Identificador no existe.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } catch (error) {
        console.error('Error checking user:', error);
        toast.error('An error occurred. Please try again later.');
      }
    };
  
    return (
      <div>
        <WarningPopup></WarningPopup>
        <div className="min-h-screen flex flex-col justify-center items-center pb-32 pt-10 maindiv pl-5 pr-5">
          <ToastContainer />
          <WhatsAppChatButton
            phoneNumber="5491169572365"
            message={"tengo una consulta."}
          ></WhatsAppChatButton>
          <div className="max-w-4xl w-full bg-white rounded-lg shadow-xl overflow-hidden">
            <div className="sm:p-10">
              <img src={logo} alt="Lisual Logo" className="mx-auto mb-8" />
              <h1 className="text-4xl font-bold text-center text-gray-800 mb-6">
                La plataforma de marketing N°1 para obras de construcción
              </h1>
              <p className="text-center text-gray-600 mb-8">
                Ingrese su identificador para acceder a su dashboard.
              </p>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col sm:flex-row gap-4"
              >
                <input
                  type="text"
                  placeholder="ID"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Ingresar
                </button>
              </form>
              <p>
                Si aun no tenes tu ID, hace click{" "}
                <a
                  className="text-blue-600"
                  target="_blank"
                  href="https://wa.link/317jf0"
                >
                  aqui
                </a>
              </p>
            </div>
            <div className="bg-gray-50 px-10 sm:px-20 sm:py-10">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                Nuestros Servicios
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                <div className="flex flex-col items-center">
                  <img src={landing1} alt="Camara" className="mb-2 w-9/12" />
                  <h3 className="font-semibold">Camara en vivo</h3>
                </div>
                <div className="flex flex-col items-center">
                  <img src={landing3} alt="Video" className="mb-2 w-9/12" />
                  <h3 className="font-semibold">Contenido Ilimitado</h3>
                </div>
                <div className="flex flex-col items-center">
                  <img src={landing2} alt="Ventas" className="mb-2 w-9/12" />
                  <h3 className="font-semibold">Potenciamos tus ventas</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  

export default LandingPage;